import * as React from "react";
import Layout from "../components/common/Layout";
import Button from "../components/common/Button";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="flex justify-center items-center px-4 flex-col mt-16 mb-[80px]">
        <h1 className="font-sub font-bold text-center text-4xl md:text-[40px] leading-[60px] mb-4 text-dsc-title">
          ページが見つかりません
        </h1>
        <p className="text-sm leading-5 tracking-[0.03] text-dsc-body text-center mb-10">
          指定されたURLのページは存在しません。
          <br />
          サイト更新などによってURLが変更になったか、URLが正しく入力されていない可能性があります。
        </p>
        <Button
          title="ホームページに戻る"
          size="lg"
          path="/"
          className="lg:px-[45px]"
        />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
